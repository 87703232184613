import React from 'react';
import classNames from 'classnames';
import { withStyles, createStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import ImageLogo from './DotDash_Vertical_Logo_White.png';
import AccountManager from './utils/AccountManager';
import { TextField } from '@material-ui/core';

const styles = ({ spacing }) => createStyles({
	root: {
		position: 'absolute',
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
		backgroundImage: 'linear-gradient(90deg,rgb(250,196,1) 0%,rgb(250,170,1) 100%)',
		zIndex: 999,
	},
	inner: {
		display: 'flex',
		flexDirection: 'column',
		maxWidth: 500,
		margin: 'auto',
		color: 'white',
		marginTop: '10vh',
		padding: '5vh',
	},
	button: {
		color: 'white',
		marginTop: spacing(3),
		float: 'right',
	},
	buttonDisabled: {
		color: 'rgba(255,255,255,0.7)',
	},
	label: {
		color: 'rgba(255,255,255,0.8)',
	},
	field: {
		color: 'white',
		marginBottom: spacing(2),
		'&:before': {
			borderColor: 'rgba(255,255,255,0.5)',
		},
		'&:hover:before': {
			borderColor: 'rgba(255,255,255,0.5) !important',
		},
	},
	warning: {
		padding: '1rem 3rem',
		marginTop: 24,
		backgroundColor: '#214a75',
	},
});

const URLPREFIX = 'https://';
const STATUS = {
	Idle: 0,
	Connecting: 1,
	Connected: 2,
};

class AddAccount extends React.Component<any, any> {
	state = {
		status: STATUS.Idle,
		warning: '',
		url: "https://chat.carebiz.dotdashtech.com/http-bind/",
		// url: "http://xmpp.dev.dotdashtech.com:5280/http-bind",
		jid: '',
		password: '',
	};

	onChange = name => ev => {
		let value = ev.target.value;

		if (name === 'url') {
			value = this.hasHTTPSPrefix(value) ? value : URLPREFIX + value;
		}

		this.setState({ [name]: value });
		this.setState({ warning: '' });
	};

	onSubmit = ev => {
		ev.preventDefault();

		this.setState({ status: STATUS.Connecting });

		let { url, jid, password } = this.state;
		let domain = this.getDomainFromJid(jid);
		if (!domain){
			domain = 'chat.carebiz.dotdashtech.com'
			jid = jid+'@'+domain
		}
		try{
			(window as any).JSXC.testBOSHServer(url, domain)
			.then(() => {
				return this.props.jsxc.start(url, jid, password);
			})
			.then(() => {
				AccountManager.get().add(url, jid, password);

				this.setState({ status: STATUS.Connected });

				this.props.onConnected();
			})
			.catch(error => {
				if (typeof error === 'string') {
					this.setState({ warning: error });
				} else if (error.message) {
					this.setState({ warning: error.message });
				} else {
					this.setState({
						warning:
							'Sorry we could not connect. Maybe your Jabber ID or password is wrong.',
					});
				}

				this.setState({ status: STATUS.Idle });
			});
		} catch(error){
			if (typeof error === 'string') {
				this.setState({ warning: error });
			} else if (error.message) {
				this.setState({ warning: error.message });
			} else {
				this.setState({
					warning:
						'Sorry we could not connect. Maybe your Jabber ID or password is wrong.',
				});
			}

			this.setState({ status: STATUS.Idle });
		}
	};

	getDomainFromJid(jid) {
		let parts = jid.split('@');

		return parts[1];
	}

	hasHTTPSPrefix(url = '') {
		for (let i = 0; i < url.length && i < URLPREFIX.length; i++) {
			if (url[i] !== URLPREFIX[i]) return false;
		}

		return true;
	}

	render() {
		const { classes } = this.props;
		const { status/*, url*/ } = this.state;
		const disabled = status === STATUS.Connecting;
		let buttonLabel = disabled ? 'Connecting...' : 'Connect';

		return (
			<div className={classes.root}>
				<div className={classes.inner}>
					<img
						src={ImageLogo}
						style={{ maxWidth: '50vh',alignSelf:'center', margin: '3vh', maxHeight: '50vh' }}
						alt="Logo"
					/>

					<form onSubmit={this.onSubmit}>
						{/* <TextField
							autoFocus
							disabled={disabled}
							required
							label="BOSH Url"
							value={url}
							onChange={this.onChange('url')}
							InputProps={{
								className: classes.field,
							}}
							InputLabelProps={{
								className: classes.label,
							}}
							fullWidth
						/> */}
						<TextField
							// type="email"
							disabled={disabled}
							required
							label="SSO Account"
							onChange={this.onChange('jid')}
							InputProps={{
								className: classes.field,
							}}
							InputLabelProps={{
								className: classes.label,
							}}
							autoComplete="off"
							fullWidth
						/>
						<TextField
							type="password"
							disabled={disabled}
							required
							label="Password"
							onChange={this.onChange('password')}
							InputProps={{
								className: classes.field,
							}}
							InputLabelProps={{
								className: classes.label,
							}}
							autoComplete="off"
							fullWidth
						/>

						{/* @TODO clear button */}
						<Button
							type="submit"
							disabled={disabled}
							className={classNames(
								classes.button,
								disabled && classes.buttonDisabled
							)}
							color="primary"
						>
							{buttonLabel} <KeyboardArrowRight />
						</Button>
						<div style={{ clear: 'both' }} />

						{this.state.warning ? (
							<div className={classes.warning}>{this.state.warning}</div>
						) : (
							''
						)}
					</form>
				</div>
			</div>
		);
	}
}

export default withStyles(styles, { withTheme: true })(AddAccount);
